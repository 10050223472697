import React from "react"
import styled, { keyframes } from "styled-components"
import icon from "../../images/favicon.png"

const fadeIn = keyframes`
0% {
  transform: rotate(0deg);
  box-shadow: 0 0 0 6px rgba(255, 99, 71, 0.1);
}

50% {
  box-shadow: 0 0 0 6px rgba(255, 99, 71, 0.9);
}

100% {
  transform: rotate(360deg);
  box-shadow: 0 0 0 6px rgba(255, 99, 71, 0.1);
}
`

const LoadingImg = styled.img`
  display: block;
  height: 64px;
  width: 64px;
  margin-left: -31px;
  margin-top: -31px;
  border: 6px solid white;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  animation: ${fadeIn} 2s linear infinite;
`

function Loader() {
  return <LoadingImg src={icon} alt="Loading" />
}

export default Loader
