import React, { useContext, useEffect, useState } from "react"
import Layout from "../components/Layout"
import styled from "styled-components"
import SEO from "../components/SEO"
import Loader from "../components/Loading"
import { Button } from "semantic-ui-react"
import { handleCharacters } from "../utils/handleCharacters"
import { device } from "../theme"
import { FavoritesContext } from "../providers/MyFavorites"

const Block = styled.div`
  margin: 0 auto;
  padding: 32px 0;
  max-width: 800px;
  a {
    font-weight: 400;
    text-decoration: underline;
  }
  p {
    font-size: 1rem;
  }
`

const RemoveFavorites = styled(Button)`
  background: none !important;
  border: 2px solid ${props => props.theme.colors.tomato} !important;
  color: #ff6347 !important;
  padding: 12px 22px !important;
  max-width: 160px;
  max-height: 42px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${device.phone} {
    flex-direction: column;
  }
`

const Title = styled.h2`
  font-size: 3rem;
  margin: 62px 0 12px;
`

const NoFavorites = styled.h3`
  font-size: 1.5rem;
  color: grey;
  margin-top: 22px;
`
const Favorite = styled.div`
  display: flex;
  align-items: center;
  margin: 22px 0;
  height: 230px;
`

const FavoriteTitle = styled.h3`
  font-size: 1.5rem;
  margin: 0;

  @media ${device.phone} {
    font-size: 1rem;
  }
`

const FavoriteBrand = styled.p`
  font-size: 1rem;
  color: #444;
  margin: 6px 0;
`

const FavoritePrice = styled.p`
  font-size: 1rem;
  color: #444;
  margin: 0;
`

const GoToShop = styled.a`
  display: block;
  width: fit-content;
  margin: 12px 0;
  font-size: 1rem;
  color: #fff;
  padding: 12px 22px;
  text-decoration: none !important;
  border-radius: 6px;
  background: ${props => props.theme.colors.green};

  &:hover {
    color: #fff;
  }
`

const FavoriteImage = styled.img`
  max-width: 150px;
  max-height: 220px;
  margin: 0 22px 0 0;
  display: block;
  cursor: pointer;
`

const RemoveButton = styled.button`
  display: block;
  width: fit-content;
  margin: 12px 0;
  font-size: 1rem;
  color: ${props => props.theme.colors.tomato};
  cursor: pointer;
  padding: 12px 22px;
  text-decoration: none !important;
  border-radius: 6px;
  border: none;
  outline: none;
  margin-left: 8px;
  box-shadow: inset 0 0 0 2px ${props => props.theme.colors.tomato};
  background: none;
  transition: background 0.1s ease-out;

  @media ${device.phone} {
    margin: 0;
  }
`

const Buttons = styled.div`
  display: flex;
  @media ${device.phone} {
    flex-direction: column;
  }
`

const Popup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 99999999999;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
`
const PopContainer = styled.div`
  max-width: 520px;
  background: #fff;
`

const PopImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

function FavoritesPage() {
  const { favorites, setFavorites } = useContext(FavoritesContext)
  const [popupVisible, setPopup] = useState(false)

  const clearAll = () => {
    window.localStorage.clear()
    setFavorites([])
  }

  const removeFavorite = key => {
    const removeProduct = [...favorites].filter(prod => prod.key !== key)
    window.localStorage.setItem("favorite", JSON.stringify(removeProduct))
    setFavorites(removeProduct)
  }

  return (
    <React.Fragment>
      <SEO title={"Mine favoritter"} description={"Læs om Lineout"} />
      <Layout>
        <Block>
          <Header>
            <Title>Mine favoritter</Title>
            {favorites && favorites.length ? (
              <RemoveFavorites onClick={() => clearAll()}>
                Fjern favoritter
              </RemoveFavorites>
            ) : null}
          </Header>

          {!favorites || !favorites.length ? (
            <NoFavorites>Du har ingen valgte favoritter</NoFavorites>
          ) : (
            favorites?.map((favorite, key) => (
              <Favorite key={favorite.title}>
                <FavoriteImage
                  onClick={() => setPopup(key)}
                  src={favorite.image}
                  alt={favorite.title}
                />
                <div>
                  <FavoriteTitle>
                    {handleCharacters(favorite.title)}
                  </FavoriteTitle>
                  <FavoriteBrand>
                    {handleCharacters(favorite.brand)}
                  </FavoriteBrand>
                  <FavoritePrice>{favorite.price} kr</FavoritePrice>
                  <Buttons>
                    <GoToShop href={favorite.url} target="_blank">
                      Gå til shop
                    </GoToShop>
                    <RemoveButton onClick={() => removeFavorite(favorite.key)}>
                      Fjern favorit
                    </RemoveButton>
                  </Buttons>
                </div>
                {popupVisible === key && (
                  <Popup onClick={() => setPopup(false)}>
                    <PopContainer>
                      <PopImage src={favorite.image} alt={favorite.title} />
                    </PopContainer>
                  </Popup>
                )}
              </Favorite>
            ))
          )}
        </Block>
      </Layout>
    </React.Fragment>
  )
}

export default FavoritesPage
